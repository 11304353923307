import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Icon from './Icon'
import { base, variants, sizes } from './Button.styles'

function Button({ variant, href, size, icon, iconLibrary, iconPosition, iconStyle, children, ...props }) {
  // eslint-disable-next-line prettier/prettier
  const styles = [base, variants[variant], sizes[size].font, variant !== 'text' ? sizes[size].padding : null]
  // Did we supply an external link?
  if (href && href.startsWith('http')) {
    return (
      <a href={href} css={styles} {...props}>
        {icon && iconPosition === 'before' ? (
          <Icon icon={icon} library={iconLibrary} css={iconStyle} size={size} position={iconPosition} />
        ) : null}
        {children}
        {icon && iconPosition === 'after' ? (
          <Icon icon={icon} library={iconLibrary} css={iconStyle} size={size} position={iconPosition} />
        ) : null}
      </a>
    )
  }

  // Did we supply an internal link?
  if (href) {
    return (
      <Link to={href} css={styles} {...props}>
        {icon && iconPosition === 'before' ? (
          <Icon icon={icon} library={iconLibrary} css={iconStyle} size={size} position={iconPosition} />
        ) : null}
        {children}
        {icon && iconPosition === 'after' ? (
          <Icon icon={icon} library={iconLibrary} css={iconStyle} size={size} position={iconPosition} />
        ) : null}
      </Link>
    )
  }

  // No link? Button probably has an onClick handler
  return (
    <button type="button" css={styles} {...props}>
      {icon && iconPosition === 'before' ? (
        <Icon icon={icon} library={iconLibrary} css={iconStyle} size={size} position={iconPosition} />
      ) : null}
      <span>{children}</span>
      {icon && iconPosition === 'after' ? (
        <Icon icon={icon} library={iconLibrary} css={iconStyle} size={size} position={iconPosition} />
      ) : null}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /**
   * Is this the principal call to action on the page?
   */
  variant: PropTypes.oneOf(['primary', 'secondary', 'outline', 'text']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['inherit', 'xsmall', 'small', 'medium', 'large', 'input']),
  /**
   * Where the button will take the user to
   */
  href: PropTypes.string,
  /**
     Icon Library
  */
  iconLibrary: PropTypes.oneOf(['solid', 'outline']),
  /**
     Icon for button
  */
  icon: PropTypes.string,
  /**
     Icon Position
  */
  iconPosition: PropTypes.oneOf(['before', 'after']),

  /**
     Icon Styling
  */
  iconStyle: PropTypes.object,
}

Button.defaultProps = {
  variant: 'text',
  size: 'medium',
  href: null,
  icon: null,
  iconPosition: 'after',
}

export default Button
