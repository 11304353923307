import React from 'react'
import PropTypes from 'prop-types'
import tw, { css } from 'twin.macro'
import { Link, useStaticQuery, graphql } from 'gatsby'
import parse from 'html-react-parser'
import { EnvelopeIcon, DevicePhoneMobileIcon, MapPinIcon } from '@heroicons/react/24/outline'
import { StaticImage } from 'gatsby-plugin-image'
import Container from '../Container/Container'
import { Menus } from './SiteFooter.styles'
import { Facebook, Twitter, Instagram, LinkedIn, YouTube } from '../../assets/svg'
import Button from '../Button/Button'

function Footer({ ...props }) {
  const {
    footerMenus,
    policyMenu,
    wp: themeSettings,
  } = useStaticQuery(graphql`
    query FooterQuery {
      footerMenus: allWpMenu(
        filter: { menuItems: { nodes: { elemMatch: { locations: { in: [FOOTER_MENU_1, FOOTER_MENU_2, FOOTER_MENU_3] } } } } }
        sort: { fields: locations }
      ) {
        nodes {
          ...footerQuery
        }
      }
      policyMenu: allWpMenu(filter: { menuItems: { nodes: { elemMatch: { locations: { eq: SITE_POLICIES_MENU } } } } }) {
        nodes {
          ...footerQuery
        }
      }
      wp {
        themeSettings {
          settings {
            address
            copyright
            email
            fax
            fieldGroupName
            footerLogo {
              localFile {
                publicURL
              }
              altText
            }
            footerSocialIcons
            telephone
            socialLinks {
              facebook
              twitter
              instagram
              linkedIn
              youtube
              fieldGroupName
            }
          }
        }
      }
    }
  `)

  const { settings } = themeSettings.themeSettings

  // how many columns do we need for menus
  const colCount = footerMenus.nodes.length

  // how many columns do we need for menus
  const count = policyMenu.nodes.length

  return (
    <footer id="foot">
      <Container variant="fullWidth" tw="pt-12 pb-8 px-4 bg-white text-gray-700" {...props}>
        <Container tw="lg:grid lg:grid-cols-3 lg:gap-8 items-center">
          <div tw="md:mb-8 lg:mb-0 lg:col-span-1 lg:pr-8">
            {settings.footerLogo ? (
              <Link to="/" tw="block mb-4 mx-auto px-4 max-w-sm lg:mx-0 md:px-0">
                <img src={settings.footerLogo.localFile.publicURL} alt={settings.footerLogo.altText} />
              </Link>
            ) : null}
            <div tw="flex flex-col items-center lg:items-start">
              {settings.address ? (
                <p tw="text-sm pb-2 pr-4 flex">
                  <MapPinIcon tw="mr-3 text-purple w-auto h-4" />
                  {parse(settings.address)}
                </p>
              ) : null}

              {settings.telephone ? (
                <p tw="text-sm pb-2 pr-4 flex items-center">
                  <DevicePhoneMobileIcon tw="mr-3 text-purple w-auto h-4" />

                  <a href="tel:+{settings.telephone}" tw="font-bold">
                    {settings.telephone}
                  </a>
                </p>
              ) : null}

              {settings.email ? (
                <p tw="text-sm pb-2 pr-4 flex items-center">
                  <EnvelopeIcon tw="mr-3 text-purple w-auto h-4 margin-top[3px]" />

                  <a href={`mailto:${settings.email}`} tw="font-bold">
                    {settings.email}
                  </a>
                </p>
              ) : null}
              {settings.footerSocialIcons ? (
                <div tw="flex justify-start items-center pt-5 md:pt-0 lg:pt-5 space-x-3">
                  <span tw="font-sans text-sm text-purple-600 mb-0">Follow Us</span>

                  {settings.socialLinks.facebook ? (
                    <a
                      href={settings.socialLinks.facebook}
                      target="_blank"
                      tw="text-purple w-5 h-auto transition-colors hover:text-[#1877F2] focus:text-[#1877F2]"
                      rel="noreferrer"
                      aria-label="Facebook Button"
                    >
                      <Facebook />
                    </a>
                  ) : null}

                  {settings.socialLinks.twitter ? (
                    <a
                      href={settings.socialLinks.twitter}
                      target="_blank"
                      tw="text-purple w-5 h-auto transition-colors hover:text-[#1DA1F2] focus:text-[#1DA1F2]"
                      rel="noreferrer"
                      aria-label="Twitter Button"
                    >
                      <Twitter />
                    </a>
                  ) : null}

                  {settings.socialLinks.instagram ? (
                    <a
                      href={settings.socialLinks.instagram}
                      target="_blank"
                      tw="text-purple w-5 h-auto transition-colors hover:text-[#E4405F] focus:text-[#E4405F]"
                      rel="noreferrer"
                      aria-label="Instagram Button"
                    >
                      <Instagram />
                    </a>
                  ) : null}

                  {settings.socialLinks.linkedIn ? (
                    <a
                      href={settings.socialLinks.linkedIn}
                      target="_blank"
                      tw="text-purple w-5 h-auto transition-colors hover:text-[#0A66C2] focus:text-[#0A66C2]"
                      rel="noreferrer"
                      aria-label="LinkedIn Button"
                    >
                      <LinkedIn />
                    </a>
                  ) : null}

                  {settings.socialLinks.youtube ? (
                    <a
                      href={settings.socialLinks.youtube}
                      target="_blank"
                      tw="text-purple w-5 h-auto transition-colors hover:text-[#CD201F] focus:text-[#CD201F]"
                      rel="noreferrer"
                      aria-label="Youtube Button"
                    >
                      <YouTube />
                    </a>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <Menus columns={colCount}>
            {footerMenus.nodes.map(menu => (
              <div key={menu.name}>
                <h2 tw="text-lg pb-3 mb-2 border-b">{menu.name}</h2>
                <ul tw="space-y-2 ml-0">
                  {menu.menuItems.nodes.map(menuItem => (
                    <li tw="list-none" key={menuItem.id}>
                      <Button variant="text" size="small" tw="font-normal inline-block text-gray-700" href={menuItem.path}>
                        {menuItem.label}
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Menus>
        </Container>
      </Container>
      <Container variant="fullWidth" tw="py-6 bg-purple-50 border-t border-purple-100 text-gray-700 px-4">
        <Container tw="flex flex-col md:flex-row flex-col-reverse justify-between items-center">
          <span tw="md:w-1/2 text-xs text-center md:text-left mt-4 md:mt-0">
            © Copyright {new Date().getFullYear()} {settings.copyright ? `— ${settings.copyright}` : null}
          </span>
          {policyMenu.nodes.map(menu => (
            <div tw="flex justify-end space-x-10 tablet:justify-center" key={menu.name}>
              {menu.menuItems.nodes.map(menuItem => (
                <div key={menuItem.id}>
                  <Button variant="text" size="small" tw="font-normal text-xs inline-block text-gray-700" href={menuItem.path}>
                    {menuItem.label}
                  </Button>
                </div>
              ))}
            </div>
          ))}
        </Container>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  //
}

export default Footer
