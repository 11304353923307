import React, { createContext, useContext, useMemo } from 'react'
import { useScrollYPosition } from '../hooks/useScrollPosition'

const TIMEOUT = 250
export const SCROLL_DIRECTION = {
  Down: 'down',
  None: 'none',
  Up: 'up',
}

export const ScrollContext = createContext({
  scrollTop: 0,
  previousScrollTop: 0,
  time: TIMEOUT,
  amountScrolled: 0,
  direction: SCROLL_DIRECTION.None,
  velocity: 0,
})

// eslint-disable-next-line react/prop-types
function ScrollProvider({ children }) {
  const { yPos, prevYPos, time } = useScrollYPosition(TIMEOUT)

  const amountScrolled = useMemo(() => yPos - prevYPos, [yPos, prevYPos])

  const direction = useMemo(() => {
    if (amountScrolled < 0) {
      return SCROLL_DIRECTION.Up
    }
    if (amountScrolled > 0) {
      return SCROLL_DIRECTION.Down
    }
    return SCROLL_DIRECTION.None
  }, [amountScrolled])

  const velocity = useMemo(() => Math.abs(amountScrolled / time), [amountScrolled, time])

  const value = useMemo(
    () => ({
      yPos,
      prevYPos,
      time,
      amountScrolled,
      direction,
      velocity,
    }),
    [yPos, prevYPos, time, amountScrolled, direction, velocity]
  )

  return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
}

// Creates a nice little shorthand for useContext(ScrollContext)
const useScrollContext = () => useContext(ScrollContext)

export { useScrollContext, ScrollProvider }
