import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { css } from 'twin.macro'
import { down } from '../../utils/screens'
import LogoColor from './umbrella-logo-color.inline.svg'
import LogoWhite from './umbrella-logo-white.inline.svg'

function Logo({ title, variant, ...props }) {
  // Setup dynamic component posibilities
  const components = {
    white: LogoWhite,
    color: LogoColor,
  }
  const TagName = components[variant]

  return (
    <Link to="/" aria-label={title}>
      <TagName {...props} />
    </Link>
  )
}

Logo.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.oneOf(['color', 'white']),
}

Logo.defaultProps = {
  title: '',
  variant: 'color',
}

export default Logo
