import tw, { styled, theme } from 'twin.macro'
import Button from '../Button'
import { SubmenuContainer } from '../Submenu/Submenu.styles'

export const MenuContainer = styled.div`
  ${tw`relative flex justify-center items-center`}

  &:hover, &:focus-within {
    ${SubmenuContainer} {
      opacity: ${({ open }) => (open ? '1' : '0')};
      transform: ${({ open }) => (open ? 'rotateX(0)' : 'perspective(200px) rotateX(-15deg) rotateZ(0)')};
      visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    }
  }
`

export const MenuItem = styled(Button)`
  ${tw`relative ml-4 mr-2 py-4`}

  &:after {
    height: 3px;
    border-radius: 1.5px;
    content: '';
    position: absolute;
    bottom: 0;
    left: -8%;
    width: 110%;
    background-color: ${theme`colors.purple.200`};
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
    transform: scale(0);
    transform-origin: center;
  }
`
