import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { styled } from 'twin.macro'
import UnderlineSVG from './brushstroke.svg'
import useOnScreen from '../../hooks/useOnScreen'

const Underline = styled.span`
  position: relative;
  display: inline-block;
  z-index: 1;

  &:after {
    display: ${props => (props.isVisible ? 'static' : 'none')};
    animation: ${props => props.isVisible && 'show 300ms ease'};
    content: '';
    position: absolute;
    left: -1%;
    transform: rotate(-0.5deg);
    top: calc(100% + var(--underline-mt, 0px));
    background: url(${UnderlineSVG});
    background-size: 100% var(--underline-h, 0px);
    background-position: top center;
    background-repeat: no-repeat;
    width: 104%;
    height: var(--underline-h, 100%);
    z-index: -1;
  }

  @keyframes show {
    0% {
      opacity: 0;
      clip-path: inset(0px 100% 0px 0px);
    }
    100% {
      opacity: 100%;
      clip-path: inset(0px 0% 0px 0px);
    }
  }
`

function UnderlinedText({ children, ...props }) {
  const ref = useRef(null)
  const isVisible = useOnScreen(ref)

  return (
    <span ref={ref} {...props}>
      <Underline isVisible={isVisible}>{children}</Underline>
    </span>
  )
}

UnderlinedText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default UnderlinedText
