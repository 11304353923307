import React from 'react'
// custom typefaces
import '@fontsource/plus-jakarta-sans'
import '@fontsource/plus-jakarta-sans/800.css'

import { Global } from '@emotion/react'
import { GlobalStyles as BaseStyles, css } from 'twin.macro'
import { GlobalStyles } from './src/styles/GlobalStyles'
import { PrintStyles } from './src/styles/print'
import Layout from './src/components/Layout'
import { ScrollProvider } from './src/context'

export const wrapRootElement = ({ element }) => (
  <>
    <BaseStyles />
    <Global
      styles={css`
        ${GlobalStyles}
        ${PrintStyles}
      `}
    />
    {element}
  </>
)

// Setup our layout
export const wrapPageElement = ({ element }) => (
  <ScrollProvider>
    <Layout>{element}</Layout>
  </ScrollProvider>
)

// Do things when we change the route
export const onRouteUpdate = ({ prevLocation }) => {
  // This will remove the focus on whatever was on the prev location and set it to the skip nav element
  if (prevLocation !== null) {
    const skipLink = document.querySelector('#skip-nav')

    if (skipLink) {
      skipLink.focus()
    }
  }
}
