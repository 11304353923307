import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import { SubmenuContainer, SubmenuItem } from './MobileSubMenu.styles'

function MobileSubMenu({ menuData, click }) {
  return (
    <SubmenuContainer>
      <div tw="py-2 grid grid-rows-1">
        {menuData.children.map(childItem => (
          <SubmenuItem
            size="small"
            onClick={click}
            key={childItem.id}
            href={childItem.path}
            iconLibrary={childItem.menuAcf.icon ? 'outline' : 'solid'}
            icon={childItem.menuAcf.icon ? childItem.menuAcf.icon : 'ChevronRightIcon'}
            iconStyle={childItem.menuAcf.icon ? tw`text-purple-300 w-5 h-5 mr-3` : tw`text-green w-5 h-5`}
            iconPosition="before"
          >
            {childItem.label}
          </SubmenuItem>
        ))}
      </div>
    </SubmenuContainer>
  )
}

MobileSubMenu.propTypes = {
  menuData: PropTypes.object,
  click: PropTypes.func,
}

export default MobileSubMenu
