import tw, { styled } from 'twin.macro'
import Button from '../Button'

export const SubmenuContainer = styled.div`
  position: absolute;
  width: max-content;
  transition: all 125ms linear;
  transform-origin: top center;
  top: calc(100% - 5px);
  padding-top: 20px;
  opacity: ${({ open }) => (open ? '1' : '0')};
  transform: ${({ open }) => (open ? 'rotateX(0)' : 'perspective(200px) rotateX(-15deg) rotateZ(0)')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  z-index: 10;
`

export const SubmenuInner = styled.div`
  position: relative;
  z-index: 1;
  ${tw`bg-white border border-gray-100 rounded-2xl`}
  box-shadow: 4px 4px 20px 5px #81318f40;
  &:before {
    background-color: #fff;
    content: '';
    height: 20px;
    left: 10%;
    position: absolute;
    top: 0;
    width: 80%;
  }

  &:after {
    border-width: 0 10px 10px 10px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
    content: '';
    display: block;
    filter: drop-shadow(0 2px 6px rgba(13, 23, 75, 0.25));
    height: 0;
    left: 50%;
    position: absolute;
    top: -8px;
    transform: translate3d(-50%, 0, 0);
    width: 0;
    z-index: -1;
  }
`

export const SubmenuItem = styled(Button)`
  ${tw`justify-start`}
  ${tw`px-3 py-2 mb-1 rounded-md whitespace-normal z-10`}
  ${tw`hover:bg-yellow-50 focus:bg-purple-50 focus:ring-1 focus:ring-purple-100`}
`
