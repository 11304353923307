import tw, { css, styled } from 'twin.macro'

// eslint-disable-next-line prettier/prettier
const columnVariants = [
  tw`grid-cols-1`,
  tw`grid-cols-1 md:grid-cols-2`,
  tw`grid-cols-1 md:grid-cols-3`,
  tw`grid-cols-1 md:grid-cols-4`,
]

// eslint-disable-next-line prettier/prettier
export const Menus = styled.div(() => [
  tw`lg:col-span-2 p-4 grid gap-8 lg:pl-16 order-first md:order-last text-center md:text-left`,
  ({ columns = 0 }) => columnVariants[columns - 1],
])
