import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import tw from 'twin.macro'
import Button from '../Button/Button'

function SecondaryMenu({ size, ...props }) {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(locations: { eq: SECONDARY_MENU }) {
        id
        menuItems {
          nodes {
            parentId
            label
            path
            id
            childItems {
              nodes {
                path
                label
              }
            }
          }
        }
      }
    }
  `)

  return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
    <div tw="w-auto md:pl-2">
      <div>
        {wpMenu.menuItems.nodes.map((menuItem, i, arr) => (
          <Button
            size={size}
            css={i === arr.length - 1 && i !== 0 ? tw`ml-3` : tw`ml-0`}
            tw="inline-block"
            variant="secondary"
            key={menuItem.id}
            href={menuItem.path}
            {...props}
          >
            {menuItem.label}
          </Button>
        ))}
      </div>
    </div>
  ) : (
    <div>No menu added to secondary menu</div>
  )
}

SecondaryMenu.propTypes = {
  //
}

export default SecondaryMenu
