import React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import tw from 'twin.macro'
import { isServer } from '../../utils/common'

import { sizes } from './Button.styles'
import IconErrorBoundary from '../Error/IconErrorBoundary'

const HeroiconsOutline = loadable(() => import(`@heroicons/react/24/outline`), {
  resolveComponent: (components, props) => components[props.icon],
})
const HeroiconsSolid = loadable(() => import(`@heroicons/react/24/solid`), {
  resolveComponent: (components, props) => components[props.icon],
})

function Icon({ library, icon, size, position, className }) {
  if (!icon || isServer) return null

  const components = {
    outline: HeroiconsOutline,
    solid: HeroiconsSolid,
  }
  const TagName = components[library]

  return (
    <IconErrorBoundary>
      <TagName
        icon={icon}
        className={className}
        css={[
          // eslint-disable-next-line prettier/prettier
          tw`inline-block`,
          position && position === 'before' ? tw`mr-1` : tw`ml-1`,
          sizes[size].icon,
        ]}
      />
    </IconErrorBoundary>
  )
}

Icon.propTypes = {
  /**
   * How large should the button be?
   */
  library: PropTypes.oneOf(['solid', 'outline']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['inherit', 'xsmall', 'small', 'medium', 'large', 'input']),
  /**
     Optional HeroIcon for button
  */
  icon: PropTypes.string,
  /**
     Optional HeroIcon for button
  */
  position: PropTypes.oneOf(['before', 'after']),

  className: PropTypes.string,
}

Icon.defaultProps = {
  library: 'outline',
  size: 'medium',
  icon: null,
  position: 'after',
}

export default Icon
