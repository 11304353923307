import tw, { styled } from 'twin.macro'

export const StyledBurger = styled.button`
  ${tw`flex flex-col justify-between bg-transparent space-y-1 border-0 cursor-pointer`}

  &:focus {
    outline: none;
  }

  div {
    ${tw`w-7 ml-2 -mr-3 rounded`}
    ${tw`bg-purple`}
    position: relative;
    height: 0.2rem;
    transition: all 0.2s ease;
    transform-origin: 88%;

    :first-of-type {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }

    :nth-of-type(2) {
      ${tw`w-6`}
      opacity: ${({ open }) => (open ? '0' : '0.8')};
      transform: ${({ open }) => (open ? 'translateX(-15px)' : 'translateX(0)')};
    }

    :nth-of-type(3) {
      ${({ open }) => (open ? tw`w-7` : tw`w-5`)};
      opacity: ${({ open }) => (open ? '1' : '0.6')};
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
  }
`
