import React, { useState, createContext, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import tw from 'twin.macro'

import { flatListToHierarchical } from '../../utils/flatListToHierarchical'
import Submenu from '../Submenu'
import MegaMenu from '../MegaMenu'
import { MenuContainer, MenuItem } from './MainMenu.styles'

const MenuContext = createContext(null)

function MainMenu() {
  const [open, setOpen] = useState(false)
  const data = useStaticQuery(graphql`
    {
      wpMenu(locations: { eq: PRIMARY_MENU }) {
        id
        menuItems {
          nodes {
            ...menuItemQuery
            parentId
            menuAcf {
              icon
              linkAnchor
              ...megaMenuQuery
            }
          }
        }
      }
    }
  `)

  if (!data.wpMenu) return <div>No menu items have been added</div>

  // GraphlQl query returns a flat list of menu items, this helper function turns it into a proper hierarchy
  const menuHierachy = flatListToHierarchical(data.wpMenu.menuItems.nodes)

  const chooseSubmenuType = menuItem => {
    // Do we have a child menu or mega menu?
    if (menuItem.children.length || menuItem.menuAcf.megaMenu)
      return (
        <MenuContainer open={open} key={menuItem.id}>
          <MenuItem
            onMouseEnter={() => setOpen(true)}
            onClick={() => setOpen(false)}
            size="small"
            href={menuItem.path}
            icon="ChevronDownIcon"
            iconStyle={tw`text-green w-5 h-5`}
            iconLibrary="solid"
          >
            {menuItem.label}
          </MenuItem>
          {menuItem.menuAcf.megaMenu ? (
            <MegaMenu megaMenuData={menuItem.menuAcf.megaMenu.megaMenuBuilder} setOpen={setOpen} />
          ) : (
            <Submenu setOpen={setOpen} menuData={menuItem} />
          )}
        </MenuContainer>
      )

    // None of the above
    return (
      <MenuItem onClick={() => setOpen(false)} size="small" key={menuItem.id} tw="mx-4 py-4" href={menuItem.path}>
        {menuItem.label}
      </MenuItem>
    )
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <MenuContext.Provider value={{ setOpen }}>
      <div tw="w-auto">
        <div tw="flex">{menuHierachy.map(menuItem => chooseSubmenuType(menuItem))}</div>
      </div>
    </MenuContext.Provider>
  )
}

export const useMenu = () => useContext(MenuContext)

export default MainMenu
