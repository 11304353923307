import React from 'react'
import PropTypes from 'prop-types'
import tw, { styled } from 'twin.macro'
import parse, { domToReact, attributesToProps } from 'html-react-parser'
import UnderlinedText from '../UnderlinedText'
import { Link } from './Wysiwyg.styles'
import Button from '../Button'

const inlineTailwind = {
  'text-xs': tw`text-xs`,
  'text-sm': tw`text-sm`,
  'text-base': tw`text-base`,
  'text-lg': tw`text-lg`,
  'text-xl': tw`text-xl`,
  'text-2xl': tw`text-2xl`,
  'text-3xl': tw`text-3xl`,
  'text-4xl': tw`text-4xl`,
}

function Wysiwyg({ content, children: jsxChildren, ...rest }) {
  const parseMe = content || jsxChildren || ''

  const options = {
    replace: ({ type, parent, prev, next, startIndex, name, attribs, children }) => {
      if (!attribs) {
        return
      }

      const props = attributesToProps(attribs)

      if (attribs['data-component'] === 'Underline') {
        return (
          <UnderlinedText {...props} tw="text-green-400">
            {domToReact(children, options)}
          </UnderlinedText>
        )
      }

      if (attribs['data-tw']) {
        const Component = name
        return (
          <Component {...props} css={inlineTailwind[props['data-tw']]}>
            {domToReact(children, options)}
          </Component>
        )
      }

      if (attribs['data-component'] === 'Button') {
        // console.log('wysiwyg props', attribs, children, options)
        return <Button {...props}>{domToReact(children, options)}</Button>
      }

      // Format links in text
      if (name === 'a' && type === 'tag') {
        return <Link {...props}>{domToReact(children, options)}</Link>
      }

      // if (attribs.id === 'main') {
      //   return <h1 style={{ fontSize: 42 }}>{domToReact(children, options)}</h1>
      // }

      // if (attribs.class === 'prettify') {
      //   return <span style={{ color: 'hotpink' }}>{domToReact(children, options)}</span>
      // }
    },
  }
  // return <div tw="p-3">👋 I'm a Wysiwyg component</div>
  return (
    <div tw="!font-proxima" {...rest}>
      {parse(parseMe, options)}
    </div>
  )
}

Wysiwyg.defaultProps = {
  content: '',
}

export default Wysiwyg
