import tw, { styled } from 'twin.macro'
import Button from '../Button'

export const SubmenuContainer = styled.div`
  width: 100%;
  transition: visibility 0.1s ease-in-out, opacity 0.2s ease-in-out, height 0.25s ease-in-out;
  transform-origin: top center;
  visibility: hidden;
  height: 0;
  opacity: 0;
  z-index: 10;
`

export const SubmenuItem = styled(Button)`
  ${tw`justify-start px-0`}
  ${tw`py-2 mb-1 rounded-md whitespace-normal z-10`}
  ${tw`focus:bg-purple-50 focus:ring-1 focus:ring-purple-100`}
`
