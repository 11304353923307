import tw, { styled, theme, css } from 'twin.macro'
import Button from '../Button'
import { SubmenuContainer } from '../MobileSubMenu/MobileSubMenu.styles'

export const StyledMenu = styled.nav`
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: #ffffff;
  box-shadow: 4px 4px 4px -4px #81318f40;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  height: 100vh;
  max-width: 300px;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out, visibility 0.5s ease-in-out;

  @media (max-width: 500px) {
    width: 100%;
    max-width: 100%;
    margin-top: 3rem;
    transform: ${({ open }) => (open ? 'translatey(0)' : 'translatey(-200%)')};
    z-index: -125;
  }
`

export const MegaContainer = styled.div`
  width: 100%;
  transition: visibility 0.1s ease-in-out, opacity 0.2s ease-in-out, height 0.25s ease-in-out;
  transform-origin: top center;
  /* transform: perspective(200px) rotateX(-15deg) rotateZ(0); */
  visibility: hidden;
  height: 0;
  opacity: 0;
`

export const MenuItem = styled(Button)`
  ${tw`relative text-lg pb-1`}

  @media (max-width: 380px) {
    ${tw`text-lg`}
  }
  &:after {
    height: 3px;
    border-radius: 1.5px;
    content: '';
    position: absolute;
    bottom: 0;
    left: -8%;
    width: 110%;
    background-color: ${theme`colors.purple.200`};
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
    transform: scale(0);
    transform-origin: center;
  }
`

export const MenuContainer = styled.div`
  ${tw`relative flex-col`}
  &:hover,
  &:focus-within {
    ${SubmenuContainer} {
      opacity: 1;
      transform: rotateX(0);
      visibility: visible;
      height: 100%;
    }
    ${MegaContainer} {
      opacity: 1;
      transform: rotateX(0);
      visibility: visible;
      height: 100%;
    }
  }
`
