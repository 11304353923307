import tw, { styled, theme } from 'twin.macro'
import Button from '../Button'

export const Link = styled(Button)`
  display: inline;
  ${tw`relative font-semibold whitespace-normal`}

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: -1px;
    border-radius: 1.5px;
    background-color: ${theme`colors.purple.200`};
    opacity: 0;
    transition: opacity 300ms, transform 150ms;
    transform: scale(0);
    transform-origin: center;
    ${tw`rounded`}
  }

  &:hover::after,
  &:focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
    transform: scale(1);
  }
`
