import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import tw, { css } from 'twin.macro'
import { useHeaderContext } from './SiteHeaderContext'
import Search from '../Search'
import Container from '../Container'
import MainMenu from '../MainMenu'
import SecondaryMenu from '../SecondaryMenu/SecondaryMenu'
import Logo from '../Logo'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { up, down } from '../../utils/screens'
import MobileNav from '../MobileNav/MobileNav'

function SiteHeader() {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  const { isVisible } = useHeaderContext()

  // Media query helpers
  const isUpLg = useMediaQuery(up('xl'))
  const isUpSm = useMediaQuery(up('md'))
  const isDownLg = useMediaQuery(down('xl'))

  // TODO: Temporary, should be passed as state OR come from the graphlQL query
  const negative = false

  // TODO: eek, messy refactor
  const style = [
    tw`bg-white transform-gpu transition-transform ease-in-out duration-300`,
    negative
      ? css`
          ${tw`bg-purple-400`}
          a,
          button {
            font-size: 14px;
            color: white !important;
          }
        `
      : null,
    isVisible ? tw`translate-y-0` : tw`-translate-y-full`,
  ]

  const image = css`
    transition: all 0.3s ease;
    max-width: 260px;
    margin-left: 0.5rem;

    ${down('lg')} {
      max-width: 220px;
    }
    ${down('sm')} {
      max-width: 190px;
      /* margin-left: 0; */
    }
  `
  return (
    <Container
      as="header"
      id="header"
      variant="fullWidth"
      tw="bg-opacity-95 shadow-sm backdrop-filter backdrop-blur-sm fixed top-0 left-0 z-max"
      css={style}
    >
      <Container tw="px-4 py-2 flex justify-between items-center phone:px-4">
        {/* <Link to="/">{parse(title)}</Link> */}
        <Logo variant="color" title={title} css={image} />
        <div tw="flex items-center space-x-2">
          {isUpLg ? <MainMenu /> : null}
          {isUpSm ? <SecondaryMenu size="small" /> : null}
          <Search />
          {isDownLg ? <MobileNav /> : null}
        </div>
      </Container>
    </Container>
  )
}

SiteHeader.propTypes = {
  //
}

export default SiteHeader
