import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import Container from '../Container'
import Button from '../Button'

const Label = styled.label`
  ${tw`text-sm pb-3 text-purple pr-2 w-full tablet:pb-5 tablet:pr-0`}
`
const Input = styled.input`
  ${tw`box-border w-full text-black bg-white border`}
`
const Form = styled.form`
  ${tw`flex h-[100px] justify-between items-start mt-3 tablet:flex-col tablet:items-start tablet:h-auto`}
`

const NewsletterSubscribe = () => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [honeyPot, setHoneyPot] = useState('')

  const [buttonText, setButtonText] = useState('Submit')

  const [errors, setErrors] = useState('')

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showFailureMessage, setShowFailureMessage] = useState(false)

  const handleValidation = () => {
    const tempErrors = {}
    let isValid = true
    const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

    if (firstName.length <= 0) {
      tempErrors.firstName = true
      isValid = false
    }
    if (email.length <= 0 || regex.test(email) === false) {
      tempErrors.email = true
      isValid = false
    }
    if (lastName.length <= 0) {
      tempErrors.lastName = true
      isValid = false
    }

    if (honeyPot.length > 0) {
      tempErrors.honeyPot = true
      isValid = false
    }

    setErrors({ ...tempErrors })
    return isValid
  }

  const handleOnSubmit = async e => {
    e.preventDefault()

    if (handleValidation()) {
      setButtonText('Sending...')
      try {
        const requestBody = {
          body: {
            email,
            first_name: firstName,
            last_name: lastName,
          },
        }

        const response = await fetch('/api/newsletterSubscribe', requestBody)

        if (!response.ok) {
          // Handle HTTP error responses
          throw new Error(`Request failed with status: ${response.status}`)
        }

        // Reset the form and set success message
        setFirstName('')
        setLastName('')
        setEmail('')
        setShowFailureMessage(false)
        setShowSuccessMessage(true)
        setButtonText('Submit')
      } catch (error) {
        // Handle errors that occur during the fetch operation
        // You can display an error message to the user or log the error for debugging
        setShowFailureMessage(true)
        console.error('Fetch error:', error)
        setButtonText('Submit')
      }
    }
  }

  return (
    <Container variant="wide" tw="py-10 tablet:py-5" id="letter">
      <div tw="pb-4">
        <h2>Join our mailing list!</h2>
        <p>Sign-up to be notified of important information and updates from Umbrella.</p>
      </div>
      <div tw="text-left">
        {showSuccessMessage && <p tw="text-green font-semibold text-sm">Thank you! You've succesfully joined our mailing list</p>}
        {showFailureMessage && <p tw="text-red-500 text-sm font-semibold">Oops! Something went wrong, please try again.</p>}
      </div>
      <Form method="post" onSubmit={handleOnSubmit}>
        <Label htmlFor="first_name">
          <strong>First Name</strong>
          <Input
            name="first_name"
            value={firstName}
            onChange={e => {
              setFirstName(e.target.value)
            }}
          />
          {errors?.firstName && <p tw="text-red-500 font-bold">Please add your first name</p>}
        </Label>
        <Label htmlFor="last_name">
          <strong>Last Name</strong>
          <Input
            name="last_name"
            value={lastName}
            onChange={e => {
              setLastName(e.target.value)
            }}
          />
          {errors?.lastName && <p tw="text-red-500 font-bold">Please add your last name</p>}
        </Label>
        <Label htmlFor="email">
          <strong>Email</strong>
          <Input
            name="email"
            value={email}
            onChange={e => {
              setEmail(e.target.value)
            }}
          />
          {errors?.email && <p tw="text-red-500 font-bold">Email cannot be empty or invalid.</p>}
        </Label>
        {/* HONEYPOT FIELD */}
        <Label htmlFor="otherEmail" tw="hidden">
          <strong>Email</strong>
          <Input
            name="otherEmail"
            placeholder="NOT A REAL FIELD"
            value={honeyPot}
            onChange={e => {
              setHoneyPot(e.target.value)
            }}
          />
        </Label>
        <Button tw="mt-5 tablet:my-auto" variant="secondary" type="submit">
          {buttonText}
        </Button>
      </Form>
    </Container>
  )
}

NewsletterSubscribe.propTypes = {
  //
}

export default NewsletterSubscribe
