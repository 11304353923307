import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'twin.macro'

import { SubmenuContainer as MegaMenuContainer, SubmenuInner as MegaMenuInner } from '../Submenu/Submenu.styles'

import Wysiwyg from '../Wysiwyg'
import Advertisement from '../Advertisement'
import Menu from '../Menu'

const AVAILABLE_COMPONENTS = {
  Wysiwyg,
  Advertisement,
  Menu,
}

const megaMenuBuilderComponents = components =>
  // console.log('THIS IS PROPS', components)
  components.map((component, idx) => {
    const { __typename, ...props } = component
    const TagName = AVAILABLE_COMPONENTS[__typename.split('_').pop()]
    return React.createElement(TagName, { key: `${__typename}_${idx}`, ...props })
  })

function MegaMenu({ megaMenuData: { columns } }) {
  const gridTemplate = {
    gridTemplateColumns: `repeat(${columns.length}, minmax(0, 1fr))`,
  }

  return (
    <MegaMenuContainer>
      <MegaMenuInner>
        <div tw="grid space-x-4 rounded-2xl overflow-hidden" css={gridTemplate}>
          {columns.map((column, id) => (
            <div
              key={id}
              css={[
                css`
                  width: 255px;
                  z-index: 1;
                `,
              ]}
            >
              {megaMenuBuilderComponents(column.components)}
            </div>
          ))}
        </div>
      </MegaMenuInner>
    </MegaMenuContainer>
  )
}
MegaMenu.propTypes = {
  megaMenuData: PropTypes.object,
}

export default MegaMenu
