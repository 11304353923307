import tw, { css, styled } from 'twin.macro'
import PropTypes from 'prop-types'
import { up, down, only, between } from '../../utils/screens'

const containerVariants = {
  base: tw`relative w-full mx-auto px-4 phone:px-0`,
  // Named class sets
  default: tw`max-w-screen-2xl`,

  fullWidth: css`
    ${tw`w-screen px-0`}
    left: calc(-50vw + 50%);
  `,
  wide: tw`w-5/6`,
  narrow: tw`max-w-screen-lg`,
  blog: tw`max-w-3xl`,
}

/**
 * A container component for laying out content
 *
 * @param   {string}   variant   accepts 'default', 'fullWidth', 'wide', 'narrow', 'blog'
 */
// eslint-disable-next-line prettier/prettier
const Container = styled.div(() => [
  containerVariants.base,
  ({variant}) => containerVariants[variant]
])

Container.propTypes = {
  variant: PropTypes.oneOf(['default', 'fullWidth', 'wide', 'narrow', 'blog']),
}

Container.defaultProps = {
  /**
    variant description ?
  */
  variant: 'default',
}

export default Container
