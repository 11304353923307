import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import { useMenu } from '../MainMenu/MainMenu'
import { SubmenuContainer, SubmenuInner, SubmenuItem } from './Submenu.styles'

function Submenu({ menuData, click }) {
  const { setOpen } = useMenu()
  return (
    <SubmenuContainer>
      <SubmenuInner>
        <div tw="px-2 py-2 grid grid-rows-1">
          {menuData.children.map(childItem => (
            <SubmenuItem
              size="small"
              onClick={() => setOpen(false)}
              key={childItem.id}
              href={childItem.path}
              icon={childItem.menuAcf.icon ? childItem.menuAcf.icon : null}
              iconPosition="before"
              iconStyle={tw`text-purple-300 w-5 h-5 mr-3`}
            >
              {childItem.label}
            </SubmenuItem>
          ))}
        </div>
      </SubmenuInner>
    </SubmenuContainer>
  )
}

Submenu.propTypes = {
  menuData: PropTypes.object,
  click: PropTypes.func,
}

export default Submenu
