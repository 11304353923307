/**
 * Gatsby source worpdress' graphQL queries for menus always return a flat list. This utility function turns it back into a hierarchical list
 *
 * @param   {undefined[]}  data         [data description]
 * @param   {[type]}       idKey        [idKey description]
 * @param   {[type]}       id           [id description]
 * @param   {[type]}       parentKey    [parentKey description]
 * @param   {[type]}       parentId     [parentId description]
 * @param   {[type]}       childrenKey  [childrenKey description]
 * @param   {[type]}       children     [children description]
 *
 * @return  {[]}                        [return description]
 */
export const flatListToHierarchical = (data = [], { idKey = 'id', parentKey = 'parentId', childrenKey = 'children' } = {}) => {
  const tree = []
  const childrenOf = {}

  data.forEach(item => {
    const newItem = { ...item }
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
    childrenOf[id] = childrenOf[id] || []
    newItem[childrenKey] = childrenOf[id]
    parentId ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem) : tree.push(newItem)
  })

  // console.log('tree', tree)

  return tree
}
