import { useState, useEffect, useMemo, useRef } from 'react'
import throttle from 'lodash/throttle'
import { isClient } from '../utils/common'

const getScrollPosition = () => (isClient ? { x: window.pageXOffset, y: window.pageYOffset } : { x: 0, y: 0 })

export const useScrollPosition = (timeout = 250) => {
  const defaultPosition = useMemo(() => getScrollPosition(), [])
  const previousPosition = useRef(defaultPosition)
  const [position, setScrollPosition] = useState(getScrollPosition())

  useEffect(() => {
    if (!isClient) return
    let requestRunning = null

    const handleScroll = () => {
      if (isClient && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          const scrollPos = getScrollPosition()
          setScrollPosition(scrollPos)
          previousPosition.current = scrollPos
          requestRunning = null
        })
      }
    }

    // Throttle the rerenders
    const handleDocumentScrollThrottled = throttle(handleScroll, timeout)
    window.addEventListener('scroll', handleDocumentScrollThrottled)

    return () => window.removeEventListener('scroll', handleDocumentScrollThrottled)
  }, [timeout])

  return {
    pos: position,
    prevPos: previousPosition.current,
    time: timeout,
  }
}

// Simple functions to only retrieve the X or y parts
export const useScrollXPosition = () => {
  const { pos, prevPos, time } = useScrollPosition()
  return { xPos: pos.x, prevXPos: prevPos.x, time }
}

export const useScrollYPosition = () => {
  const { pos, prevPos, time } = useScrollPosition()
  return { yPos: pos.y, prevYPos: prevPos.y, time }
}
