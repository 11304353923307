import React, { createContext, useMemo, useContext, useEffect } from 'react'

import { scrollDirection, useScrollContext } from '../../context'
import { useVisibility } from '../../hooks/useVisibility'

const TOP_START = 50 // how many px from the top of the page should the show/hide behaviour start

export const HeaderContext = createContext({
  isVisible: true,
})

export function HeaderProvider({ children }) {
  const { isVisible, show, hide } = useVisibility(true)
  const { yPos, direction } = useScrollContext()

  useEffect(() => {
    const shouldShow = yPos <= TOP_START || direction !== scrollDirection.Down
    const shouldHide = yPos > TOP_START && direction === scrollDirection.Down
    if (shouldShow) {
      show()
    } else if (shouldHide) {
      hide()
    }
  }, [yPos, direction, hide, show])

  const value = useMemo(
    () => ({
      isVisible,
    }),
    [isVisible]
  )

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
}

export const useHeaderContext = () => useContext(HeaderContext)
