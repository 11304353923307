import tw, { css } from 'twin.macro'

export const GlobalStyles = css`
  html {
    ${tw`bg-white text-black cursor-default leading-none min-h-full `}
  }
  body {
    ${tw`font-sans text-base antialiased`}
  }
  html,
  body {
    ${tw`flex flex-col flex-1`}
  }

  code {
    ${tw`bg-gray-200 p-2 mx-2 rounded-sm`}
  }

  input {
    ${tw`rounded-md border-0 h-12 px-5 font-sans focus:outline-none`}
  }

  a {
    ${tw`text-purple-400`}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    --underline-h: 0.35em;
    --underline-mt: -0.08em;
    ${tw`font-proxima text-purple-400 font-black my-4`}
  }

  h1 {
    ${tw`text-2xl lg:text-3xl`}
  }

  h2 {
    ${tw`text-xl lg:text-2xl leading-none`}
  }

  h3 {
    ${tw`text-lg: lg:text-xl leading-none`}
  }

  h4 {
    ${tw`text-[1.9rem] leading-none`}
  }

  h5 {
    ${tw`text-base leading-none`}
  }

  h6 {
    ${tw`text-sm`}
  }

  p {
    --underline-h: 0.25em;
    ${tw`pb-6 leading-7 last:pb-0`}

    > span {
      ${tw`whitespace-nowrap phablet:whitespace-normal`}
    }
  }

  .hero-text {
    margin-top: 0;
    ${tw`text-2xl lg:text-4xl`}
  }

  hr {
    ${tw`pb-6`}
  }

  figure {
    width: auto !important;
    position: relative;
  }

  ul,
  ol {
    list-style: revert !important;
    margin-left: 1.3rem;
  }
  li:last-child {
    padding-bottom: 1.5rem;
  }

  figcaption {
    position: absolute;
    color: white;
    z-index: 800;
    background: linear-gradient(to bottom, transparent, #000 100%);
    bottom: 0;
    font-style: italic;
    font-family: 'proxima-soft';
    width: 100%;
    font-size: 12px;
    line-height: 1.2em;
    padding: 3rem 1rem 0.5rem 1rem;
  }

  td {
    ${tw`border-purple border border-solid p-3 align-top`}
  }

  .tablepress {
    width: 100%;
    ${tw`mb-8`}
  }

  thead {
    ${tw`text-white bg-purple`}
  }

  tbody {
    padding: 1rem;
  }

  th {
    padding: 0.5rem 1rem;
    text-align: left;
  }

  tbody tr {
    background-color: white;
  }

  tbody tr:nth-of-type(odd) {
    background-color: hsl(0, 0%, 97%);
  }

  blockquote {
    ${tw`bg-gray-100 p-3 w-[90%] m-auto phablet:w-full`}
    border-left: 4px solid purple;
  }

  .alignnone {
  }

  .alignright {
    float: right;
    margin-left: 1.5rem;
  }

  .alignleft {
    float: left;
    margin-right: 1.5rem;
  }

  .aligncenter {
    display: flex !important;
    flex-direction: column;
    margin: 1.5rem auto;
  }

  .rounded-top {
    border-radius: 1.5rem 1.5rem 0 0;
  }

  .rounded-bottom {
    border-radius: 0 0 1.5rem 1.5rem;
  }

  .rounded {
    border-radius: 1.5rem;
  }

  .size-thumbnail {
    max-width: 200px;
    max-height: 200px;
  }

  .size-medium {
    max-height: 350px;
  }

  .size-full {
    max-width: 48rem;
    width: 100%;
  }

  .aspect-ratio-16-9 {
    aspect-ratio: 16/9;
    object-fit: cover;
  }
`
