import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { StyledMenu, MenuItem, MenuContainer, MegaContainer } from './MobileNav.styles'
import { flatListToHierarchical } from '../../utils/flatListToHierarchical'
import MobileSubMenu from '../MobileSubMenu'
import Burger from '../HamburgerIcon'
import Logo from '../Logo'
import SecondaryMenu from '../SecondaryMenu/SecondaryMenu'

function MobileNav() {
  const navBreakpoint = 768
  const phoneBreakpoint = 500
  const [windowWidth, setWindowWidth] = useState()
  const [open, setOpen] = useState(false)
  const node = useRef()
  const data = useStaticQuery(graphql`
    {
      wpMenu(locations: { eq: PRIMARY_MENU }) {
        id
        menuItems {
          nodes {
            ...menuItemQuery
            parentId
            menuAcf {
              icon
              linkAnchor
              ...megaMenuQuery
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth)
    })
    if (open === true && windowWidth < 505) {
      // 👇 add class to body element
      document.documentElement.style.overflow = 'hidden'
    }
    if (open === false) {
      // 👇 add class to body element
      document.documentElement.style.overflow = 'visible'
    }
  }, [open, windowWidth])

  if (!data.wpMenu) return <div>No menu items have been added</div>

  const menuHierachy = flatListToHierarchical(data.wpMenu.menuItems.nodes)

  const chooseSubmenuType = menuItem => {
    // Do we have a child menu or mega menu?
    if (menuItem.children.length || menuItem.menuAcf.megaMenu)
      return (
        <MenuContainer key={menuItem.id}>
          <MenuItem
            onClick={() => setOpen(!open)}
            tw="py-4"
            size="small"
            href={menuItem.path}
            icon="ChevronDownIcon"
            iconStyle={tw`text-green w-5 h-5`}
            iconLibrary="solid"
          >
            {menuItem.label}
          </MenuItem>
          {menuItem.menuAcf.megaMenu ? (
            <>
              {menuItem.menuAcf.megaMenu.megaMenuBuilder.columns.map((col, index) => (
                <div key={index}>
                  {col.components[0].menuContent && (
                    <>
                      {col.components[0].menuContent.buttons.map((button, i) => {
                        const parsedLink = new URL(button.link.url)
                        return (
                          <MegaContainer key={i}>
                            <Link
                              key={index}
                              onClick={() => setOpen(!open)}
                              to={parsedLink.pathname}
                              tw="flex items-center py-2 grid grid-cols-2 max-w-[350px] hover:bg-yellow-50 focus:bg-purple-50 focus:ring-1 focus:ring-purple-100"
                            >
                              <div tw="flex items-center">
                                <ChevronRightIcon tw="text-green w-5 h-5" />
                                <span tw="font-bold font-proxima text-purple text-[16px]">{button.text}</span>
                              </div>
                              <span tw="font-proxima text-xs text-black">{button.ageGroup}</span>
                            </Link>
                          </MegaContainer>
                        )
                      })}
                    </>
                  )}
                </div>
              ))}
            </>
          ) : (
            <MobileSubMenu click={() => setOpen(!open)} menuData={menuItem} />
          )}
        </MenuContainer>
      )

    // None of the above
    return (
      <MenuItem onClick={() => setOpen(!open)} size="small" key={menuItem.id} tw="py-4" href={menuItem.path}>
        {menuItem.label}
      </MenuItem>
    )
  }

  return (
    <div ref={node} tw="pr-2">
      <Burger open={open} setOpen={setOpen} />
      <StyledMenu open={open}>
        {windowWidth > phoneBreakpoint && (
          <Logo onClick={() => setOpen(!open)} variant="color" title="Mobile Nav Menu" tw="w-[220px] mb-6 phablet:w-[240px]" />
        )}
        {menuHierachy.map(menuItem => chooseSubmenuType(menuItem))}
        {windowWidth < navBreakpoint && (
          <SecondaryMenu css={windowWidth < navBreakpoint ? tw`flex flex-col ml-0` : tw``} size="medium" tw="mt-5" />
        )}
      </StyledMenu>
    </div>
  )
}

export default MobileNav
