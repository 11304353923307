import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { useMenu } from '../MainMenu/MainMenu'
import Button from '../Button'

const MenuContainer = ({ title, buttons, children }) => {
  const { setOpen } = useMenu()
  return (
    <div tw="p-4">
      <span tw="text-lg text-purple font-bold font-proxima">{title}</span>
      {children}
      <div tw="space-y-2">
        {buttons.map((button, index) => {
          const parsedLink = new URL(button.link.url)
          return (
            <Button onClick={() => setOpen(false)} key={index} tw="flex items-center justify-between" href={parsedLink.pathname}>
              <div tw="flex items-center">
                <ChevronRightIcon tw="text-green w-5 h-5" />
                <span tw="font-bold font-proxima text-purple text-[16px]">{button.text}</span>
              </div>
              <span tw="font-proxima text-xs text-black">{button.ageGroup}</span>
            </Button>
          )
        })}
      </div>
    </div>
  )
}

const Menu = ({ menuContent }) => {
  const imageObject = getImage(menuContent.image?.gatsbyImage)
  // const [open, setOpen] = useState(true)

  return (
    <MenuContainer title={menuContent.header} buttons={menuContent.buttons}>
      {menuContent.image && imageObject && (
        <GatsbyImage
          image={imageObject}
          alt={menuContent.image.altText}
          tw="rounded my-2 flex justify-center px-0 h-[148px] w-[223px]"
          imgStyle={{ borderRadius: `4px` }}
        />
      )}
    </MenuContainer>
  )
}

MenuContainer.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.object),
  //
}

Menu.propTypes = {
  menuContent: PropTypes.object,
}

export default Menu
