import tw, { css } from 'twin.macro'

export const SearchStyles = css`
  .ais-Hits-list {
    list-style: none !important;
    margin: 0;
  }
  .ais-Hits-item:hover {
    background-color: #fffbe6;
  }
  .ais-Hits-item {
    padding: 0.9rem 0.5rem;
    border-top: 0.5px solid rgba(128, 0, 128, 0.1);
    border-bottom: 0.5px solid rgba(128, 0, 128, 0.1);
  }
  .ais-Hits-item:last-child {
    padding-bottom: 0.9rem;
  }

  .ais-SearchBox-form input {
    width: 100%;
    /* border: 1px solid #81318f; */
    /* border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; */
    ${tw`shadow-md`}
    padding: 0.5rem;
    /* box-shadow: 1px 1px 8px 5px rgba(0, 0, 0, 0.065); */
  }

  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    display: none;
  }

  .ais-Hits {
    padding-bottom: 1rem;
  }
`
