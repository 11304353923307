import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledBurger } from './HamburgerIcon.styles'

function Burger({ open, setOpen }) {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)} title="Mobile navigation button">
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

Burger.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

function HamburgerIcon({ open, setOpen }) {
  return <Burger open={open} setOpen={setOpen} />
}

HamburgerIcon.propTypes = {
  //
}

export default HamburgerIcon
