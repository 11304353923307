import tw, { css } from 'twin.macro'

export const base = tw`flex items-center font-bold transition-all duration-150 whitespace-nowrap`

export const variants = {
  primary: tw`rounded-full w-min my-auto text-white shadow-xl bg-green-400 hover:bg-green-500 transform-gpu hover:scale-102 hover:shadow-xl focus:scale-102 focus:shadow-xl`,
  secondary: tw`rounded-full w-min bg-purple-400 text-white hover:bg-purple-500 transform-gpu hover:scale-102 focus:scale-102`,
  outline: tw`rounded-full w-min bg-transparent border border-purple-200 text-purple hover:bg-purple-500 hover:text-white transform-gpu hover:scale-102 focus:scale-102`,
  text: tw`text-purple-400 hover:text-purple-500 focus:text-purple-700`,
}

export const sizes = {
  inherit: {
    padding: tw`p-0`,
    font: css`
      font-size: inherit;
    `,
    icon: tw`w-4 h-4`,
  },
  xsmall: {
    padding: tw`px-4 py-2`,
    font: tw`text-xs`,
    icon: tw`w-4 h-4`,
  },
  small: {
    padding: tw`px-5 py-2`,
    font: tw`text-sm`,
    icon: tw`w-4 h-4`,
  },
  medium: {
    padding: tw`px-6 py-2`,
    font: tw`text-base`,
    icon: tw`w-5 h-5`,
  },
  large: {
    padding: tw`px-4 py-3 md:px-8 md:py-4`,
    font: tw`text-sm md:text-base`,
    icon: tw`w-7 h-7`,
  },
  input: {
    padding: tw`px-6 py-2 md:padding[11px 30px]`,
    font: tw`text-base md:text-lg`,
    icon: tw`w-5 h-5`,
  },
}
