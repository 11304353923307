import React, { useEffect, useState, useRef } from 'react'
import tw, { css } from 'twin.macro'
import { MagnifyingGlassIcon, TagIcon, CalendarIcon } from '@heroicons/react/24/outline'
import { SearchBox, InstantSearch, Hits, Index, connectStateResults } from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch'
import { Link } from 'gatsby'
import Button from '../Button'
import { SearchStyles } from './Search.styles'

const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_API_KEY)

function CustomHits({ hit }) {
  // TODO: should return a "no results found" if there are no results
  return (
    <Link to={hit.uri} tw="text-black font-proxima my-2">
      <div tw="flex pb-1">
        <div tw="flex items-center">
          <CalendarIcon tw="text-purple w-4 h-4 mr-1" />
          <span tw="text-xs font-bold">{hit.date}</span>
        </div>
        {hit.categories && (
          <div tw="flex ml-4">
            <TagIcon tw="text-purple w-4 h-4 mr-1" />
            <span tw="text-xs font-bold">{hit.categories?.nodes[0]?.name}</span>
          </div>
        )}
      </div>
      <span tw="mr-2 leading-6 block">{hit.title}</span>
    </Link>
  )
}

const Results = connectStateResults(({ searchResults: res, children }) =>
  res && res.nbHits > 0 ? children : <div tw="ml-2 py-2">No results found</div>
)

const Stats = connectStateResults(
  ({ searchResults: res }) =>
    res &&
    res.nbHits > 0 && (
      <span tw="font-proxima text-xs text-white font-bold">
        {res.nbHits} result{res.nbHits > 1 ? `s` : ``}
      </span>
    )
)

const Search = () => {
  const [searchClicked, setSearchClicked] = useState(false)

  useEffect(() => {
    if (searchClicked === true) {
      // 👇 add class to body element
      document.documentElement.style.overflow = 'hidden'
    }
    if (searchClicked === false) {
      // 👇 add class to body element
      document.documentElement.style.overflowY = 'auto'
    }
  }, [searchClicked])

  return (
    <div css={SearchStyles}>
      <Button tw="pl-2" onClick={() => setSearchClicked(true)} title="Search Button">
        <MagnifyingGlassIcon altText="Search Button" tw="text-purple w-6 h-6" />
      </Button>
      {searchClicked && (
        <div
          css={css`
            background-color: rgba(0, 0, 0, 0.5);
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            margin: auto;
          `}
        >
          <div tw="overflow-auto mt-[4rem] mx-auto phablet:mx-2 bg-white border border-gray-300 rounded-md shadow-2xl max-w-[500px] max-h-[70%]">
            <InstantSearch indexName="wp_searchable_posts" searchClient={client}>
              <SearchBox tw="sticky top-0" />
              <Index indexName="wp_pages">
                <div tw="p-2 bg-purple flex justify-between items-center">
                  <span tw="font-proxima font-bold text-white text-lg">Pages:</span>
                  <Stats />
                </div>
                <Results>
                  <Hits hitComponent={CustomHits} />
                </Results>
              </Index>
              <Index indexName="wp_posts">
                <div tw="p-2 bg-purple flex justify-between items-center">
                  <span tw="font-proxima font-bold text-white text-lg">Posts:</span>
                  <Stats />
                </div>
                <Results>
                  <Hits hitComponent={CustomHits} />
                </Results>
              </Index>
              <Index indexName="program_handbook">
                <div tw="p-2 bg-purple flex justify-between items-center">
                  <span tw="font-proxima font-bold text-white text-lg">Handbook:</span>
                  <Stats />
                </div>
                <Results>
                  <Hits hitComponent={CustomHits} />
                </Results>
              </Index>
            </InstantSearch>
          </div>
          <Button
            variant="primary"
            css={css`
              margin: 1rem auto;
              /* padding: 1rem 2rem; */
            `}
            onClick={() => setSearchClicked(false)}
          >
            Close
          </Button>
        </div>
      )}
    </div>
  )
}

Search.propTypes = {
  //
}

export default Search
