import { theme } from 'twin.macro'

/**
 * NOTE: Keep this in sync with the (custom) Tailwind theme `screens` config.
 * @see https://tailwindcss.com/docs/breakpoints
 */
export const screens = theme('screens')

// The maximum value is calculated as the minimum of the next one less 0.02px.
// @see https://www.w3.org/TR/mediaqueries-4/#mq-min-max
const getNextBpValue = bp => `${parseInt(bp) - 0.02}px`

export const up = bp => {
  const screen = screens[bp]
  return `@media only screen and (min-width: ${screen})`
}

export const down = bp => {
  const screen = getNextBpValue(screens[bp])
  return `@media only screen and (max-width: ${screen})`
}

export const between = (bpMin, bpMax) => {
  const screenMin = screens[bpMin]
  const screenMax = getNextBpValue(screens[bpMax])
  return `@media only screen and (min-width: ${screenMin}) and (max-width: ${screenMax})`
}

export const only = bp => {
  const screenKeys = Object.keys(screens)
  const currentKeyIndex = screenKeys.indexOf(bp)
  const nextBp = screenKeys[currentKeyIndex + 1]
  return nextBp ? between(bp, nextBp) : up(bp)
}
