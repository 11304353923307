/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'

import autoprefixer from 'autoprefixer'
import tw, { css } from 'twin.macro'
import { useStaticQuery, graphql } from 'gatsby'
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo'
import SiteFooter from '../SiteFooter'
import Container from '../Container'
import SiteHeader from '../SiteHeader'

import Background from '../../assets/images/bg.jpg'
import NewsletterSubscribe from '../NewsletterSubscribe/NewsletterSubscribe'

function Layout({ children, ...props }) {
  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `)
  const value = useMemo(() => ({ global: seo }), [])
  return (
    <SEOContext.Provider value={value}>
      <div tw="relative">
        <div
          css={css`
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            z-index: -1;
            overflow: hidden;

            background: url(${Background});
            background-size: 120%;
            background-position: center -100px;
            background-repeat: repeat-y;
            width: 100vw;

            @media screen and (max-width: 820px) {
              background: none;
            }
          `}
        />
        <div {...props}>
          <a href="#content" id="skip-nav" tw="absolute sr-only">
            Skip Nav
          </a>
          <SiteHeader />

          <Container id="content" as="main" tw="mt-16 px-0">
            {children}
            <NewsletterSubscribe />
          </Container>
          <SiteFooter />
        </div>
      </div>
    </SEOContext.Provider>
  )
}

export default Layout
