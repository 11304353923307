import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from '../Button'

function LinkType({ link: { url, title, target }, children }) {
  if (url && url.startsWith('http')) {
    return (
      <a href={url} title={title} target={target}>
        {children}
      </a>
    )
  }

  if (url) {
    return (
      <GatsbyLink to={url} title={title}>
        {children}
      </GatsbyLink>
    )
  }

  return children
}

function Advertisement({ image, link, ...props }) {
  const gImage = getImage(image.gatsbyImage)

  return (
    <div tw="relative flex-col !h-full justify-center" {...props}>
      <LinkType link={link}>{gImage && <GatsbyImage loading="lazy" tw="!h-[100%]" image={gImage} alt={image.altText} />}</LinkType>
      <Button href={link.toString()} tw="absolute z-50 bottom-4 right-[25%] left-[25%]" variant="secondary" size="small">
        Register Now
      </Button>
    </div>
  )
}

Advertisement.propTypes = {
  image: PropTypes.shape({
    localFile: PropTypes.object,
    altText: PropTypes.string,
  }),
  link: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
  }),
}

export default Advertisement
