import tw, { css, styled } from 'twin.macro'
import NewsletterSubscribe from '../components/NewsletterSubscribe'

export const PrintStyles = css`
  @media print {
    #letter,
    #handbook-right-col,
    #foot,
    #header {
      display: none;
    }
  }
`
